import { slideDown, slideUp } from "../../js/globals.js";
import { renderPaginator, renderSvgIcons } from "../../js/layout.js";
let template = document.querySelector("#afisha-template .event-preview");
let empty = document.querySelector("#afisha-template .afisha__empty");
let list = document.querySelector("#afishalist");
let filter = document.querySelector("#afishafilter");
const url = filter?.getAttribute("action");
let paginator = document.querySelector(".paginator");
const method = filter?.getAttribute("method");
const page = filter?.querySelector('input[name="page"]');
function bindSlots() {
    if (document.querySelector(".shedule-popup")) {
        const popup = document.querySelector(".shedule-popup");
        const content = popup.querySelector(".shedule-popup__content");
        const url = popup.dataset.url;

        document.querySelectorAll("[data_ticket_system_id]").forEach((btn) => {
            btn.addEventListener("click", function () {
                content.innerHTML = `<iframe 
                src="${url}${btn.getAttribute("data_ticket_system_id")}"
                frameborder="0"
            ></iframe>`;
                popup.classList.add("_display");
            });
        });
    }
}

document.querySelectorAll(".filter-popup__block").forEach((el) => {
    if (el.querySelector(".filter-popup__block-title")) {
        el.querySelector(".filter-popup__block-title").addEventListener(
            "click",
            function () {
                if (el.classList.contains("_open")) {
                    el.classList.remove("_open");
                    slideUp(el.querySelector(".filter-popup__block-body"), 200);
                } else {
                    el.classList.add("_open");
                    slideDown(
                        el.querySelector(".filter-popup__block-body"),
                        200
                    );
                }
            }
        );
    }
});
let filterPopup = document.querySelector(".filter-popup");
document
    .querySelector(".filter-menu-open")
    ?.addEventListener("click", function () {
        filterPopup.classList.add("_open");
    });
document
    .querySelector(".filter-popup__close")
    ?.addEventListener("click", function () {
        filterPopup.classList.remove("_open");
    });
document
    .querySelector(".filter-popup__sbmt")
    ?.addEventListener("click", function () {
        filterPopup.classList.remove("_open");
    });
document.querySelectorAll(".filter-popup__categories a").forEach((el) => {
    let queryOutPage = new URLSearchParams(new FormData(filter));
    queryOutPage.set("page", 1);
    console.log(queryOutPage.toString());
    el.setAttribute(
        "href",
        `${el.getAttribute("href").split("?")[0]}?${queryOutPage.toString()}
        `
    );
});

document
    .querySelector(".filter-popup__clear")
    ?.addEventListener("click", function () {
        filter.querySelectorAll("input").forEach((item) => {
            item.checked = false;
        });
    });
function render() {
    let query = new URLSearchParams(new FormData(filter)).toString();
    let queryOutPage = new URLSearchParams(new FormData(filter));
    queryOutPage.set("page", 1);

    history.pushState({}, "", "?" + query);

    document.querySelectorAll(".filter-popup__categories a").forEach((el) => {
        el.setAttribute(
            "href",
            `${
                el.getAttribute("href").split("?")[0]
            }?${queryOutPage.toString()}`
        );
    });
    fetch(`${url}?${query}`, {
        method: "GET",
        // body: new FormData(filter),
        // headers: {
        //     "X-CSRF-TOKEN": document
        //         .querySelector('meta[name="csrf-token"]')
        //         .getAttribute("content"),
        // },
    })
        .then((response) => response.json())
        .then((data) => {
            list.innerHTML = "";
            if (data.items.length) {
                data.items.forEach((item) => {
                    let clone = template.cloneNode(true);
                    let topTags = clone.querySelector(
                        ".event-preview__top-head .event-preview__tags"
                    );
                    let bottomTags = clone.querySelector(
                        ".event-preview__top-bottom .event-preview__tags"
                    );
                    let awards = clone.querySelector(".event-preview__awards");
                    let info = clone.querySelector(".event-preview__info");
                    let bottom = clone.querySelector(".event-preview__bottom");
                    bottom.innerHTML = "";
                    if (item.buyLink && item.buyText) {
                        bottom.insertAdjacentHTML(
                            "beforeend",
                            `<a href="${item.buyLink}" ${
                                item.eventclick
                                    ? "data-eventclick='" +
                                      item.eventclick +
                                      "'"
                                    : ""
                            }  ${
                                item.data_ticket_system_id
                                    ? "data_ticket_system_id='" +
                                      item.data_ticket_system_id +
                                      "'"
                                    : ""
                            } class="btn-border">
                                <span class="btn-border__content">${
                                    item.buyText
                                }</span>
                             </a>
                             <a href="${
                                 item.link
                             }" class="btn-link">Подробнее</a>`
                        );
                    } else {
                        bottom.insertAdjacentHTML(
                            "beforeend",
                            `<a href="${item.link}" class="btn-border">
                                <span class="btn-border__content">Подробнее</span>
                            </a>`
                        );
                    }
                    topTags.innerHTML = "";
                    bottomTags.innerHTML = "";
                    clone
                        .querySelector(".event-preview__hover-link")
                        .setAttribute("href", item.link);
                    clone.querySelector(
                        ".event-preview__hover-link span"
                    ).innerText = item.title;
                    clone.querySelector(".event-preview__title").innerText =
                        item.title;
                    if (item.desc) {
                        clone
                            .querySelector(".event-preview__bottom-wrap")
                            .insertAdjacentHTML(
                                "beforebegin",
                                `<p>${item.desc}</p>`
                            );
                    }
                    clone
                        .querySelector(".event-preview__img img")
                        .setAttribute("src", item.img);

                    if (item.topTags) {
                        item.topTags.forEach((tag) => {
                            if (tag.text) {
                                topTags.insertAdjacentHTML(
                                    "beforeend",
                                    `<li class="${tag.class || ""}">
                                        ${
                                            tag.icon
                                                ? '<span class="' +
                                                  tag.icon +
                                                  '"></span'
                                                : ""
                                        } 
                                        <span>${tag.text}</span>
                                    </li>`
                                );
                            }
                        });
                    }
                    if (item.bottomTags) {
                        item.bottomTags.forEach((tag) => {
                            if (tag.text) {
                                bottomTags.insertAdjacentHTML(
                                    "beforeend",
                                    `<li class="${tag.class || ""}">
                                        ${
                                            tag.icon
                                                ? '<span class="' +
                                                  tag.icon +
                                                  '"></span'
                                                : ""
                                        }
                                        <span>${tag.text}</span>
                                    </li>`
                                );
                            }
                        });
                    }
                    if (item.awards && awards) {
                        awards.innerHTML = "";
                        item.awards.forEach((award) => {
                            awards.insertAdjacentHTML(
                                "beforeend",
                                `<li class="event-preview__award">
                    <span class="event-preview__award-top">${award.top}</span>
                    <span class="event-preview__award-name">${award.text}</span>
                    <span class="event-preview__award-year">${award.year}</span> </li>`
                            );
                        });
                    } else {
                        awards?.remove();
                    }
                    if (item.info && info) {
                        info.innerHTML = "";
                        item.info.forEach((el) => {
                            if (el.text) {
                                info.insertAdjacentHTML(
                                    "beforeend",
                                    `<li class="${el.class || ""}">
                                        ${
                                            el.icon
                                                ? '<span class="' +
                                                  el.icon +
                                                  '"></span'
                                                : ""
                                        }
                                        <span>${el.text}</span>
                                    </li>`
                                );
                            }
                        });
                    } else {
                        info?.remove();
                    }

                    list.appendChild(clone);
                });
            } else {
                list.appendChild(empty.cloneNode(true));
            }
            if (paginator) {
                renderPaginator(
                    paginator,
                    data.pages.now,
                    data.pages.length,
                    query
                );
                paginatorEvents();
            }
            renderSvgIcons(list);
            bindSlots();
            filterPopup.classList.remove("_open");
        });
}
function paginatorEvents() {
    if (paginator) {
        paginator.querySelectorAll(".paginator__link").forEach((el) => {
            el.addEventListener("click", function (e) {
                e.preventDefault();
                window.scroll({
                    top:
                        list.getBoundingClientRect().top - 170 + window.scrollY,
                    left: 0,
                    behavior: "smooth",
                });
                page.value = el.innerText;
                render();
            });
        });
    }
}
if (filter) {
    paginatorEvents();
    filter.onchange = function (e) {
        page.value = 1;

        if (window.innerWidth > 1024) {
            render();
        }
    };
    if (paginator) {
        paginator.querySelectorAll(".paginator__prev").forEach((el) => {
            el.addEventListener("click", function (e) {
                e.preventDefault();
                page.value = parseInt(el.innerText) - 1;
                window.scroll({
                    top:
                        list.getBoundingClientRect().top - 170 + window.scrollY,
                    left: 0,
                    behavior: "smooth",
                });
                render();
            });
        });
        paginator.querySelectorAll(".paginator__next").forEach((el) => {
            el.addEventListener("click", function (e) {
                e.preventDefault();
                page.value = parseInt(el.innerText) + 1;
                window.scroll({
                    top:
                        list.getBoundingClientRect().top - 170 + window.scrollY,
                    left: 0,
                    behavior: "smooth",
                });
                render();
            });
        });
    }
    filter.addEventListener("submit", function (e) {
        e.preventDefault();
        render();
    });
}

