import JustValidate from "just-validate";
if (document.querySelector("#partner-form")) {
    const validator = new JustValidate("#partner-form");

    validator
        .addField("#partner-form__name", [
            {
                rule: "required",
            },
            {
                rule: "minLength",
                value: 2,
            },
        ])
        .addField("#partner-form__number", [
            {
                rule: "required",
            },
        ])
        .addField("#partner-form__phone", [
            {
                validator: (value, context) => {
                    const matrix = context["#partner-form__phone"].elem.dataset.matrix;
                    const minLength = matrix ? matrix.length : 11;

                    return value.length < minLength ? false : true;
                }
            }
        ])
        .addField("#partner-form__email", [
            {
                rule: "required",
            },
            {
                rule: "email",
            },
        ])
        .addField("#partner-form__agree", [
            {
                rule: "required",
            },
        ]);
    validator.onSuccess((event) => {
        event.currentTarget.submit();
    });
}
