import ScrollMagic from "scrollmagic";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import gsap from "gsap";
gsap.registerPlugin(ScrollToPlugin);

const smController = new ScrollMagic.Controller();
const blocks = document.querySelectorAll(".anchor-block");

blocks.forEach((block) => {
    const scene = new ScrollMagic.Scene({
        offset: window.innerWidth >= 1200 ? -90 : -76,
        triggerElement: block,
        triggerHook: 0,
    })
        .setPin(block.querySelector(".anchor-block__list"))
        .on("enter", () => {
            block.classList.add("_is-pinned");
        })
        .on("leave", () => {
            block.classList.remove("_is-pinned");
        })
        .addTo(smController);

    window.addEventListener("resize", () => {
        scene.offset(window.innerWidth >= 1200 ? -90 : -76);
        scene.refresh();
    });
});

const anchorList = document.querySelector(".anchor-block__list");
const anchors = document.querySelectorAll(".anchor-block a[href*='#']");
const observer = new IntersectionObserver(
    (entries) => {
        console.log(entries);
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                anchors.forEach((link) => {
                    let id = link.getAttribute("href").replace("#", "");
                    if (id === entry.target.id) {
                        link.classList.add("_active");
                    } else {
                        link.classList.remove("_active");
                    }
                });
            }
            anchorList.scrollTo({
                left: document.querySelector(".anchor-block ._active")
                    .offsetLeft,

                behavior: "smooth",
            });
        });
    },
    {
        threshold: 0.2,
    }
);

for (let anchor of anchors) {
    if (document.querySelector(anchor.getAttribute("href"))) {
        observer.observe(document.querySelector(anchor.getAttribute("href")));
    }
    anchor.addEventListener("click", function (e) {
        e.preventDefault();

        const block = document.querySelector(anchor.getAttribute("href"));

        gsap.to(window, {
            duration: 0,
            scrollTo: {
                y: block,
                offsetY: 150,
            },
        });
    });
}

document.querySelectorAll(".glosarii-item").forEach((glosarii) => {
    observer.observe(glosarii);
});

