// import Swiper
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

document.querySelectorAll(".event-cards").forEach((el) => {
    new Swiper(el, {
        slidesPerView: "auto",
        setWrapperSize: true,
        spaceBetween: 12,
        pagination: {
            el: el.querySelector(".event-cards__pagi"),
            type: "bullets",
        },
        breakpoints: {
            900: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
        }
    });
});
document.querySelectorAll(".awards-slider").forEach((el) => {
    new Swiper(el, {
        slidesPerView: "auto",
        setWrapperSize: true,
        spaceBetween: 12,
        pagination: {
            el: el.querySelector(".awards-slider__pagi"),
            type: "bullets",
        },
        breakpoints: {
            700: { spaceBetween: 16, slidesPerView: 3 },
            1130: { spaceBetween: 16, slidesPerView: 3 }
        },
    });
});

document.querySelectorAll(".event-programs").forEach((el) => {
    new Swiper(el, {
        slidesPerView: "auto",
        setWrapperSize: true,
        spaceBetween: 12,
        pagination: {
            el: el.querySelector(".event-programs__pagi"),
            type: "bullets",
        },
        breakpoints: {
            700: { spaceBetween: 16, slidesPerView: 2 },
        },
    });
});
