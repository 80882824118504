import { GLOBALS, slideDown, slideUp } from '../../js/globals.js';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

// window.localStorage.removeItem('a11yVision');

/**
 * Специальная версия для слабовидящих
 */
document.querySelectorAll('.js-a11y-vision').forEach(btn => {
    btn.addEventListener('click', e => {
        e.preventDefault();
        const isOn = checkIsOn();

        if (isOn) {
            disable();
        } else {
            enable();
        }
    });
});

function checkIsOn() {
    return +window.localStorage.a11yVision === 1;
}

function enable() {
    GLOBALS.H.classList.add('a11y-vision');
    window.localStorage.setItem('a11yVision', 1);
}

function disable() {
    GLOBALS.H.classList.remove('a11y-vision');
    window.localStorage.removeItem('a11yVision');
}

if (document.querySelector('.js-a11y-vision') || GLOBALS.B.classList.contains('page-404')) {
    const isOn = checkIsOn();

    if (isOn) {
        enable();
    }
}


// Меню
const toggleMenuBtns = document.querySelectorAll('.js-a11y-v-toogle-menu');

toggleMenuBtns
    .forEach(btn => {
        const fullMenu = document.querySelector('.js-a11y-v-menu-full');
        const menuInner = document.querySelector('.js-a11y-v-menu-inner');
        if (!fullMenu) return;

        btn.addEventListener('click', function () {
            if (fullMenu.classList.contains('_is-open')) {
                fullMenu.classList.remove('_is-open');
                slideUp(fullMenu, 500);
                toggleMenuBtns.forEach(b => b.querySelector('span').innerText = 'Развернуть меню');
            } else {
                fullMenu.classList.add('_is-open');
                slideDown(fullMenu, 500);
                toggleMenuBtns.forEach(b => b.querySelector('span').innerText = 'Свернуть меню');
            }

            gsap.to(window, {
                duration: 0,
                scrollTo: {
                    y: menuInner,
                    offsetY: 10
                }
            });
        });
    });


// Меню в попапе
document.querySelectorAll('.js-a11y-v-open-menu-popup').forEach(btn => {
    btn.addEventListener('click', () => {
        document.querySelector('.js-a11y-v-menu-popup')?.classList.add('_is-open');
    });
});

document.querySelectorAll('.js-a11y-v-close-menu-popup').forEach(btn => {
    btn.addEventListener('click', () => {
        document.querySelector('.js-a11y-v-menu-popup')?.classList.remove('_is-open');
    });
});
